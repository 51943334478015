export const landscapeViewpoints = {
  type: "FeatureCollection",
  name: "viewpoints",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.91190527120153, -33.824896914079446],
      },
      properties: {
        id: "VP02",
        FID: 1,
        OID_: 0,
        Name: "VP02 George Maunder Lookout, Prospect Reservoir",
        Z: 102.99658069900001,
        Elevation_: 102.99658069900001,
        Elevation1: 337.91530182000002,
        Area_Of_St: "Western Sydney",
        Site_ID: "VP02",
        GBM_Rpt_VP: " ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.78273138675925, -33.847183303357014],
      },
      properties: {
        id: "VP04",
        FID: 3,
        OID_: 0,
        Name: "VP04 Mamre Road, Kemps Creek",
        Z: 48.853892340400002,
        Elevation_: 48.853892340400002,
        Elevation1: 160.28180414600001,
        Area_Of_St: "Western Sydney",
        Site_ID: "VP04",
        GBM_Rpt_VP: " ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.60385957204966, -33.91117766846488],
      },
      properties: {
        id: "VP07",
        FID: 6,
        OID_: 0,
        Name: "VP07 Silverdale Road",
        Z: 188.57666346100001,
        Elevation_: 188.57666346100001,
        Elevation1: 618.68986055100004,
        Area_Of_St: "Western Sydney",
        Site_ID: "VP07",
        GBM_Rpt_VP: " ",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.31230484811479, -33.732373583855924],
      },
      properties: {
        id: "VP15",
        FID: 6,
        OID_: 0,
        Name: "VP15 Echo Point Lookout",
        Z: 947.78322440700003,
        Elevation_: 947.78322440700003,
        Elevation1: 3109.52511396,
        Area_Of_St: "Blue Mountains",
        Site_ID: "VP15",
        GBM_Rpt_VP: "VP07",
      },
    },
  ],
};

export const heritageViewpoints = {
  type: "FeatureCollection",
  features: [
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.590461257643, -33.782316269234038],
      },
      properties: {
        id: "VP01",
        AP_Name: "Red Hands Cave AP",
        Label: "Red Hands Cave",
        Type: "Aboriginal Places raised during consultation (NPW Act)",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.30280424801137, -33.712358577388201],
      },
      properties: {
        id: "VP02",
        AP_Name: "Upper Kedumba River Valley AP",
        Label: "Upper Kedumba River Valley",
        Type: "Aboriginal Places raised during consultation (NPW Act)",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.31391040736898, -33.735394413541492],
      },
      properties: {
        id: "VP03",
        AP_Name: "Three Sisters AP",
        Label: "Three Sisters",
        Type: "Aboriginal Places raised during consultation (NPW Act)",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.66111997477921, -33.661271423799988],
      },
      properties: {
        id: "VP04",
        AP_Name: "Shaws Creek, Yellomundee Regional Park AP",
        Label: "Shaws Creek",
        Type: "Aboriginal Places raised during consultation (NPW Act)",
      },
    },
    {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [150.63559183077714, -33.931306406192782],
      },
      properties: {
        id: "VP05",
        AP_Name: " ",
        Label: "Bents Basin",
        Type: "Site of Aboriginal significance",
      },
    },
  ],
};

import React from "react";

import { Container } from "react-bootstrap";

import Page from "components/Page/Page";
import { mapConfig, GBMHALayer } from "src/app-config";
import {
  landscapeViewpoints,
  heritageViewpoints,
} from "src/mapData/photoGeoJSON";

const projectOverviewMapConfig = {
  ...mapConfig,
  mapSettings: {
    ...mapConfig.mapSettings,
    initialMapState: {
      ...mapConfig.mapSettings.initialMapState,
      layerOptions: {
        ...mapConfig.mapSettings.initialMapState.layerOptions,
        visible: [
          ...mapConfig.mapSettings.initialMapState.layerOptions.visible,
          "daytime-05-nojet-dept-swathe",
          "daytime-05-nojet-dept-path",
          "daytime-05-departure-swathe",
          "daytime-05-departure-path",
          "daytime-05-arrivals-swathe",
          "daytime-05-arrivals-path",
        ],
      },
    },
  },
  commonLegend: [
    ...mapConfig.commonLegend,
    {
      legendType: "icon",
      colour: "rgb(255, 255, 0)",
      text: "Arrivals",
      value: "SquareFill",
    },
    {
      legendType: "icon",
      colour: "rgb(223, 115, 255)",
      text: "Departures",
      value: "SquareFill",
    },
  ],
  layerControls: [
    ...mapConfig.layerControls,
    {
      ids: [
        "daytime-05-nojet-dept-swathe",
        "daytime-05-nojet-dept-path",
        "daytime-05-departure-swathe",
        "daytime-05-departure-path",
        "daytime-05-arrivals-swathe",
        "daytime-05-arrivals-path",
      ],
      label: "Runway 05 Day arrivals and departures",
    },
    {
      ids: [
        "daytime-23-nojet-dept-swathe",
        "daytime-23-nojet-dept-path",
        "daytime-23-departures-swathe",
        "daytime-23-departures-path",
        "daytime-23-arrivals-swathe",
        "daytime-23-arrivals-path",
      ],
      label: "Runway 23 Day arrivals and departures",
    },
    {
      ids: [
        "night-time-05-departures-swathe",
        "night-time-05-departures-path",
        "night-time-05-arrivals-swathe",
        "night-time-05-arrivals-path",
      ],
      label: "Runway 05 Night arrivals and departures",
    },
    {
      ids: [
        "night-time-23-departures-swathe",
        "night-time-23-departures-path",
        "night-time-23-arrivals-swathe",
        "night-time-23-arrivals-path",
      ],
      label: "Runway 23 Night arrivals and departures",
    },
    {
      ids: [
        "night-23-rro-departures-swathe",
        "night-23-rro-departures-path",
        "night-05-rro-arrivals-swathe",
        "night-05-rro-arrivals-path",
      ],
      label: "Night Reciprocal Runway Operations (RRO) arrivals and departures",
    },
  ],
  layers: [
    ...GBMHALayer,
    {
      id: "daytime-23-nojet-dept-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_nojet_dept_swathe-6jfj23",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.0sjpxu2i",
      },
    },
    {
      id: "daytime-23-nojet-dept-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_nojet_dept_path-btbe0r",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.6neq5jv7",
      },
    },
    {
      id: "daytime-23-departures-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_departures_swathe-1flu6g",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.80ugclon",
      },
    },
    {
      id: "daytime-23-departures-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_departures_path-bjz2v9",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.chvj1kyb",
      },
    },
    {
      id: "daytime-23-arrivals-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_arrivals_swathe-7f99bp",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.65j485y0",
      },
    },
    {
      id: "daytime-23-arrivals-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_23_arrivals_path-cumbla",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.49leglno",
      },
    },
    {
      id: "daytime-05-nojet-dept-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_nojet_dept_swathe-8grnyg",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8jy57xk4",
      },
    },
    {
      id: "daytime-05-nojet-dept-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_nojet_dept_path-0uqj5a",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.dok6ehwa",
      },
    },
    {
      id: "daytime-05-departure-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_departure_swathe-7bo8bh",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.azjslyrr",
      },
    },
    {
      id: "daytime-05-departure-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_departure_path-75n70d",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.cq5e6t9w",
      },
    },
    {
      id: "daytime-05-arrivals-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_arrivals_swathe-8bxe98",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.1auk35bn",
      },
    },
    {
      id: "daytime-05-arrivals-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm2ms4d007e01px8g060fcw",
      "source-layer": "Daytime_05_arrivals_path-7daa07",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.b3rtkvha",
      },
    },
    {
      id: "night-time-23-departures-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_23_departures_swat-05aojc",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.032h6zdb",
      },
    },
    {
      id: "night-time-23-departures-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_23_departures_path-cx2x6l",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8whv8s3n",
      },
    },
    {
      id: "night-time-23-arrivals-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_23_arrivals_swathe-axbyrz",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.7wwplzg8",
      },
    },
    {
      id: "night-time-23-arrivals-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_23_arrivals_path-6iq8iu",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.dgmkxvmj",
      },
    },
    {
      id: "night-time-05-departures-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_05_departures_swat-5p0mfs",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.4eh19qkc",
      },
    },
    {
      id: "night-time-05-departures-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_05_departures_path-5d113p",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.dqkts4le",
      },
    },
    {
      id: "night-time-05-arrivals-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_05_arrivals_swathe-34l73b",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.766ebfzl",
      },
    },
    {
      id: "night-time-05-arrivals-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_time_05_arrivals_path-74g7ra",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.7psxjd5c",
      },
    },
    {
      id: "night-23-rro-departures-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_23_RRO_departures_swath-4ifjmj",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.3r9lsn9w",
      },
    },
    {
      id: "night-23-rro-departures-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_23_RRO_departures_path-79vjl2",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.0rbstped",
      },
    },
    {
      id: "night-05-rro-arrivals-swathe",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_05_RRO_arrivals_swathe-96ppb5",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.4qslgrky",
      },
    },
    {
      id: "night-05-rro-arrivals-path",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm45fhe007i01r18f7nadzp",
      "source-layer": "Night_05_RRO_arrivals_path-54nl3y",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.5wewhxf9",
      },
    },
    ...mapConfig.layers,
  ],
};

const landscapeMapConfig = {
  ...mapConfig,
  mapSettings: {
    ...mapConfig.mapSettings,
    initialMapState: {
      ...mapConfig.mapSettings.initialMapState,
      layerOptions: {
        ...mapConfig.mapSettings.initialMapState.layerOptions,
        visible: [
          ...mapConfig.mapSettings.initialMapState.layerOptions.visible,
          "landscape-photo-montage-points",
        ],
      },
    },
  },
  icons: [
    ...mapConfig.icons,
    {
      id: "ocp-camera-icon-mapbox",
      fileName: "camera-example.png",
    },
  ],
  layerControls: [
    ...mapConfig.layerControls,
    {
      ids: ["landscape-photo-montage-points"],
      label: "Photomontage location points",
    },
  ],
  layers: [
    ...GBMHALayer,
    ...mapConfig.layers,
    {
      id: "landscape-photo-montage-points",
      type: "symbol",
      source: {
        type: "geojson",
        data: landscapeViewpoints,
      },
      layout: {
        "icon-image": "ocp-camera-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
    },
  ],
  popups: [
    {
      id: "landscape-photo-montage-popup",
      layerId: "landscape-photo-montage-points",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "VP02",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Prospect_1",
            lightboxSources: [
              {
                type: "image",
                title: "Prospect Reservoir - Runway 05",
                fileName: "Photomontage_Prospect_1",
              },
              {
                type: "image",
                title: "Prospect Reservoir - Runway 23",
                fileName: "Photomontage_Prospect_2",
              },
              {
                type: "image",
                title: "Prospect Reservoir - Runway 23",
                fileName: "Photomontage_Prospect_3",
              },
              {
                type: "image",
                title: "Prospect Reservoir - Runway 23",
                fileName: "Photomontage_Prospect_4",
              },
            ],
          },
        },
        {
          featureId: "VP04",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Luddenham_1",
            lightboxSources: [
              {
                type: "image",
                title: "Luddenham Village - Runway 05",
                fileName: "Photomontage_Luddenham_1",
              },
              {
                type: "image",
                title: "Luddenham Road - Runway 23",
                fileName: "Photomontage_Luddenham_2",
              },
              {
                type: "image",
                title: "Luddenham Village - Runway 05",
                fileName: "Photomontage_Luddenham_3",
              },
              {
                type: "image",
                title: "Luddenham Road - Runway 23",
                fileName: "Photomontage_Luddenham_4",
              },
            ],
          },
        },
        {
          featureId: "VP07",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Silverdale_1",
            lightboxSources: [
              {
                type: "image",
                title: "Silverdale Road - Runway 05",
                fileName: "Photomontage_Silverdale_1",
              },
              {
                type: "image",
                title: "Silverdale Road - Runway 23",
                fileName: "Photomontage_Silverdale_2",
              },
              {
                type: "image",
                title: "Silverdale Road - Runway 05",
                fileName: "Photomontage_Silverdale_3",
              },
              {
                type: "image",
                title: "Silverdale Road - Runway 23",
                fileName: "Photomontage_Silverdale_4",
              },
            ],
          },
        },
        {
          featureId: "VP15",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Photomontage_Echo_Point_1",
            lightboxSources: [
              {
                type: "image",
                title: "Echo Point Lookout - Runway 05",
                fileName: "Photomontage_Echo_Point_1",
              },
              {
                type: "image",
                title: "Echo Point Lookout - Runway 23",
                fileName: "Photomontage_Echo_Point_2",
              },
              {
                type: "image",
                title: "Echo Point Lookout - Runway 05",
                fileName: "Photomontage_Echo_Point_3",
              },
              {
                type: "image",
                title: "Echo Point Lookout - Runway 23",
                fileName: "Photomontage_Echo_Point_4",
              },
            ],
          },
        },
      ],
    },
  ],
  layerLegends: {
    "landscape-photo-montage-points": [
      {
        legendType: "img",
        colour: "",
        text: "Photomontage location points",
        value: "camera-example-legend",
      },
    ],
    ...mapConfig.layerLegends,
  },
};

const nationalMapConfig = {
  ...projectOverviewMapConfig,
  mapSettings: {
    ...projectOverviewMapConfig.mapSettings,
    initialMapState: {
      ...projectOverviewMapConfig.mapSettings.initialMapState,
      locationOptions: {
        zoom: 7,
        center: [150.4767, -33.2813],
        pitch: 0,
        bearing: 0,
      },
      layerOptions: {
        ...mapConfig.mapSettings.initialMapState.layerOptions,
        visible: [
          ...mapConfig.mapSettings.initialMapState.layerOptions.visible,
          "nationally-listed-places",
          "nationally-listed-places-labels",
        ],
      },
    },
  },
  icons: [
    ...mapConfig.icons,
    {
      id: "nationally-listed-places-icon",
      fileName: "nationally-listed-places.png",
    },
  ],
  layerControls: [
    {
      ids: ["nationally-listed-places", "nationally-listed-places-labels"],
      label: "Nationally listed places",
    },
    {
      ids: ["commonwealth-heritage-listed"],
      label: "Commonwealth heritage listed",
    },
    {
      ids: ["state-heritage-register", "state-heritage-register-labels"],
      label: "State heritage register",
    },
    ...projectOverviewMapConfig.layerControls,
  ],
  layers: [
    {
      id: "commonwealth-heritage-listed",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clm9zc2nk012901rafjyodaaf",
      "source-layer": "CommonwealthHeritageListed-9npr19",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.2umkqtzc",
      },
    },
    ...projectOverviewMapConfig.layers,
    {
      id: "state-heritage-register",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clm9zc2nk012901rafjyodaaf",
      "source-layer": "StateHeritageRegister-aa14j1",
      type: "circle",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8ffs55v1",
      },
    },
    {
      id: "nationally-listed-places",
      "source-layer": "NationalHeritagePlaces-ai3rxa",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.byo1i9pi",
      },
      layout: {
        "icon-image": "nationally-listed-places-icon",
        "icon-allow-overlap": true,
        "icon-size": 0.1,
      },
    },
    {
      id: "state-heritage-register-labels",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clm9zc2nk012901rafjyodaaf",
      "source-layer": "StateHeritageRegister-aa14j1",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8ffs55v1",
      },
    },
    {
      id: "nationally-listed-places-labels",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clm9zc2nk012901rafjyodaaf",
      "source-layer": "NationalHeritagePlaces-ai3rxa",
      type: "symbol",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.byo1i9pi",
      },
    },
  ],
  layerLegends: {
    ...projectOverviewMapConfig.layerLegends,
    "nationally-listed-places": [
      {
        legendType: "icon",
        colour: "rgb(0,255,0)",
        text: "Nationally listed places",
        value: "TriangleFill",
      },
    ],
    "commonwealth-heritage-listed": [
      {
        legendType: "icon",
        colour: "rgb(0,230,169)",
        text: "Commonwealth heritage listed",
        value: "SquareFill",
      },
    ],
    "state-heritage-register": [
      {
        legendType: "icon",
        colour: "rgb(255,255,190)",
        text: "State heritage register",
        value: "CircleFill",
      },
    ],
  },
};

const biodversityMapConfig = {
  ...mapConfig,
  mapSettings: {
    ...mapConfig.mapSettings,
    initialMapState: {
      ...mapConfig.mapSettings.initialMapState,
      layerOptions: {
        ...mapConfig.mapSettings.initialMapState.layerOptions,
        visible: [
          ...mapConfig.mapSettings.initialMapState.layerOptions.visible,
          "biodiversity-study-area",
          "cumberland-plain-priority",
          "cumberland-plain-priority-outline",
          "flying-fox-camps-and-buffers",
          "important-habitat-migratory",
          "important-habitat-migratory-outline",
          "important-habitat-regent",
          "important-habitat-regent-outline",
          "important-habitat-swift-parrot",
          "important-wetlands",
          "important-wetlands-outline",
          "noise-contours",
          "sepp-coastal-wetlands",
          "wildlife-buffer",
          "ghff-foraging-habitat",
        ],
      },
    },
  },
  icons: [
    ...mapConfig.icons,
    {
      id: "negative-diagonal-hatch-rgb-230-0-0",
      fileName: "negative-diagonal-hatch-rgb-230-0-0.png",
    },
    {
      id: "vertical-hatch-rgb-38-115-0",
      fileName: "vertical-hatch-rgb-38-115-0.png",
    },
    {
      id: "positive-diagonal-hatch-rgb-245-122-182",
      fileName: "positive-diagonal-hatch-rgb-245-122-182.png",
    },
    {
      id: "positive-diagonal-hatch-rgb-152-230-0",
      fileName: "positive-diagonal-hatch-rgb-152-230-0.png",
    },
  ],
  layerControls: [
    ...mapConfig.layerControls,
    {
      ids: ["noise-contours"],
      label: "Noise contours ",
    },
    {
      ids: ["biodiversity-study-area"],
      label: "Biodiversity study area",
    },
    {
      ids: ["cumberland-plain-priority", "cumberland-plain-priority-outline"],
      label: "Cumberland Plain priority Conservation lands",
    },
    {
      ids: ["flying-fox-camps-and-buffers"],
      label: "Flying-fox camps and buffers",
    },
    {
      ids: [
        "important-habitat-migratory",
        "important-habitat-migratory-outline",
      ],
      label: "Important habitat - Migratory Shorebirds",
    },
    {
      ids: ["important-habitat-regent", "important-habitat-regent-outline"],
      label: "Important habitat - Regent Honeyeater",
    },
    {
      ids: ["important-habitat-swift-parrot"],
      label: "Important habitat - Swift Parrot",
    },
    {
      ids: ["important-wetlands", "important-wetlands-outline"],
      label: "Important wetlands",
    },
    {
      ids: ["sepp-coastal-wetlands"],
      label: "SEPP Coastal Wetlands",
    },
    {
      ids: ["wildlife-buffer"],
      label: "Wildlife buffer",
    },
    {
      ids: ["ghff-foraging-habitat"],
      label: "Grey-Headed Flying-Fox Foraging Habitat",
    },
  ],
  layers: [
    ...GBMHALayer,
    {
      id: "sepp-coastal-wetlands",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "SEPP_Coastal_Wetlands-3efnlk",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8fuk7oap",
      },
    },
    {
      id: "ghff-foraging-habitat",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "GHFF_Foraging_Habitat_clip-cpg0jz",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.8b8wzd85",
      },
    },
    {
      id: "important-habitat-swift-parrot",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "Important_Habitat_Swift_Parro-dvig6x",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.25xnq532",
      },
    },
    {
      id: "cumberland-plain-priority",
      "source-layer": "Cumberland_Plain_Priority_Con-1h5d2h",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.79hs76fr",
      },
      paint: {
        "fill-pattern": "positive-diagonal-hatch-rgb-152-230-0",
      },
    },
    {
      id: "cumberland-plain-priority-outline",
      "source-layer": "Cumberland_Plain_Priority_Con-1h5d2h",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.79hs76fr",
      },
      paint: {
        "line-color": "rgb(152,230,0)",
      },
    },
    {
      id: "important-habitat-migratory",
      "source-layer": "Important_Habitat_Migratory_S-8qmuvb",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.4308d5c1",
      },
      paint: {
        "fill-pattern": "vertical-hatch-rgb-38-115-0",
      },
    },
    {
      id: "important-habitat-migratory-outline",
      "source-layer": "Important_Habitat_Migratory_S-8qmuvb",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.4308d5c1",
      },
      paint: {
        "line-color": "rgb(38,115,0)",
      },
    },
    {
      id: "important-habitat-regent",
      "source-layer": "Important_Habitat_Regent_Hone-5zn23j",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.84oekgd4",
      },
      paint: {
        "fill-pattern": "negative-diagonal-hatch-rgb-230-0-0",
      },
    },
    {
      id: "important-habitat-regent-outline",
      "source-layer": "Important_Habitat_Regent_Hone-5zn23j",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.84oekgd4",
      },
      paint: {
        "line-color": "rgb(230,0,0)",
      },
    },
    {
      id: "important-wetlands",
      "source-layer": "Important_Wetlands-8ypkso",
      type: "fill",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.5q1s0xjr",
      },
      paint: {
        "fill-pattern": "positive-diagonal-hatch-rgb-245-122-182",
      },
    },
    {
      id: "important-wetlands-outline",
      "source-layer": "Important_Wetlands-8ypkso",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.5q1s0xjr",
      },
      paint: {
        "line-color": "rgb(245,122,182)",
      },
    },
    {
      id: "flying-fox-camps-and-buffers",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "Flying-fox_camps_and_buffers-7ly8no",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.9hkppcln",
      },
    },
    {
      id: "noise-contours",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "Noise_contours-247kbr",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.3ttdkabw",
      },
    },
    {
      id: "wildlife-buffer",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "Wildlife_buffer-4h66gj",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.5cvw1d5t",
      },
    },
    {
      id: "biodiversity-study-area",
      mapboxStyle: "mapbox://styles/wsp-wsi-eis/clkm4mmxe006w01pud8co9rue",
      "source-layer": "Biodiversity_study_area-4dkjg6",
      type: "line",
      source: {
        type: "vector",
        url: "mapbox://wsp-wsi-eis.d21r1a2j",
      },
    },
    ...mapConfig.layers,
  ],
  layerLegends: {
    ...mapConfig.layerLegends,
    "noise-contours": [
      {
        legendType: "fill",
        outline: "rgb(0, 77, 168)",
        text: "Noise contours ",
        value: "solid",
      },
    ],
    "biodiversity-study-area": [
      {
        legendType: "fill",
        outline: "rgb(0, 38, 115)",
        text: "Biodiversity study area",
        value: "dotted",
      },
    ],
    "cumberland-plain-priority": [
      {
        legendType: "img",
        text: "Cumberland Plain priority Conservation lands",
        value: "positive-diagonal-hatch-rgb-152-230-0",
      },
    ],
    "flying-fox-camps-and-buffers": [
      {
        legendType: "fill",
        outline: "rgb(168, 56, 0)",
        text: "Flying-fox camps and buffers",
        value: "solid",
      },
    ],
    "important-habitat-migratory": [
      {
        legendType: "img",
        text: "Important habitat - Migratory Shorebirds",
        value: "vertical-hatch-rgb-38-115-0",
      },
    ],
    "important-habitat-regent": [
      {
        legendType: "img",
        text: "Important habitat - Regent Honeyeater",
        value: "negative-diagonal-hatch-rgb-230-0-0",
      },
    ],
    "important-habitat-swift-parrot": [
      {
        legendType: "icon",
        colour: "rgb(0, 230, 169)",
        text: "Important habitat - Swift Parrot",
        value: "SquareFill",
      },
    ],
    "important-wetlands": [
      {
        legendType: "img",
        text: "Important wetlands",
        value: "positive-diagonal-hatch-rgb-245-122-182",
      },
    ],
    "sepp-coastal-wetlands": [
      {
        legendType: "icon",
        colour: "rgb(0, 92, 230)",
        text: "SEPP Coastal Wetlands",
        value: "SquareFill",
      },
    ],
    "wildlife-buffer": [
      {
        legendType: "fill",
        outline: "rgb(255, 85, 0)",
        text: "Wildlife buffer",
        value: "dotted",
      },
    ],
    "ghff-foraging-habitat": [
      {
        legendType: "icon",
        colour: "rgb(245, 162, 122)",
        text: "Grey-Headed Flying-Fox Foraging Habitat",
        value: "SquareFill",
      },
    ],
  },
};

const heritageMapConfig = {
  ...mapConfig,
  mapSettings: {
    ...mapConfig.mapSettings,
    initialMapState: {
      ...mapConfig.mapSettings.initialMapState,
      layerOptions: {
        ...mapConfig.mapSettings.initialMapState.layerOptions,
        visible: [
          ...mapConfig.mapSettings.initialMapState.layerOptions.visible,
          "heritage-photo-points",
        ],
      },
    },
  },
  icons: [
    ...mapConfig.icons,
    {
      id: "ocp-camera-icon-mapbox",
      fileName: "camera-example.png",
    },
  ],
  layerControls: [
    ...mapConfig.layerControls,
    {
      ids: ["heritage-photo-points"],
      label: "Photo location points",
    },
  ],
  layers: [
    ...GBMHALayer,
    ...mapConfig.layers,
    {
      id: "heritage-photo-points",
      type: "symbol",
      source: {
        type: "geojson",
        data: heritageViewpoints,
      },
      layout: {
        "icon-image": "ocp-camera-icon-mapbox",
        "icon-allow-overlap": true,
        "icon-size": 0.4,
      },
    },
  ],
  popups: [
    {
      id: "heritage-photo-points-popups",
      layerId: "heritage-photo-points",
      mouseEvent: "click",
      featureProps: [
        {
          featureId: "VP01",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Heritage_Red_Hands_rock_shelter",
            lightboxSources: [
              {
                type: "image",
                title: "Red Hands rock shelter",
                fileName: "Heritage_Red_Hands_rock_shelter",
                caption:
                  "Image of Red Hands rock shelter (Image: Nick Cubbin/Department of Planning and Environment)",
              },
            ],
          },
        },
        {
          featureId: "VP02",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Heritage_Kedumba_Valley",
            lightboxSources: [
              {
                type: "image",
                title:
                  "Kedumba Valley, Greater Blue Mountains World Heritage Area",
                fileName: "Heritage_Kedumba_Valley",
                caption:
                  "Image of Kedumba Valley, Greater Blue Mountains World Heritage Area (Image: Craig Marshall/Department of Planning and Environment)",
              },
            ],
          },
        },
        {
          featureId: "VP03",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Heritage_Three_sisters",
            lightboxSources: [
              {
                type: "image",
                title: "The Three Sisters and cultural landscape context",
                fileName: "Heritage_Three_sisters",
                caption:
                  "Image of The Three Sisters and cultural landscape context (Image: Louise Clifton/Department of Planning and Environment)",
              },
            ],
          },
        },
        {
          featureId: "VP04",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Heritage_Yellomundee",
            lightboxSources: [
              {
                type: "image",
                title:
                  "Yellow Rock lookout escarpment, Yellomundee Regional Park",
                fileName: "Heritage_Yellomundee",
                caption:
                  "Image of Yellow Rock lookout escarpment, Yellomundee Regional Park (Image: John Yurasek/Department of Planning and Environment)",
              },
            ],
          },
        },
        {
          featureId: "VP05",
          moduleComponentName: "Thumbnail",
          moduleProps: {
            columnProps: { className: "p-0" },
            startingLightboxIndex: 0,
            isOpaqueUntilHover: false,
            fileName: "Heritage_Bents_Basin",
            lightboxSources: [
              {
                type: "image",
                title: "Bents Basin State Conservation Area, Nepean River",
                fileName: "Heritage_Bents_Basin",
                caption:
                  "Image of Bents Basin State Conservation Area, Nepean River (Image: Kevin McGrath/ Department of Planning and Environment)",
              },
            ],
          },
        },
      ],
    },
  ],
  layerLegends: {
    "heritage-photo-points": [
      {
        legendType: "img",
        colour: "",
        text: "Photo location points",
        value: "camera-example-legend",
      },
    ],
    ...mapConfig.layerLegends,
  },
};

const config = [
  {
    name: "Digital Draft EIS First Time Modal",
    moduleComponentName: "FirstTimeModal",
    moduleProps: {
      pageName: "digital-draft-eis",
      targetModalId: "digital-draft-eis-help",
    },
  },
  {
    name: "Aboriginal Artwork",
    moduleComponentName: "AboriginalArtworkBanner",
    rowProps: {},
    moduleProps: { encapsulate: true },
  },
  {
    name: "DigitalEIS",
    rowProps: { noGutters: true },
    moduleComponentName: "DigitalEISPage",
    moduleProps: {
      content: {
        title: "Skip to Section",
        sectionHeadingProps: {
          heading: {
            priority: 2,
            style: {
              fontWeight: "bold",
              width: "100%",
            },
            text: "Digital Draft Environmental Impact Statement",
          },
          separator: {
            style: {
              width: "100%",
            },
          },
          showClosedSubmissionAlert: false,
        },
        topics: [
          {
            id: "1-project-overview",
            title: "Project overview",
            content: {
              elementId: "1-project-overview",
              hideContent: false,
              mapConfig: {
                mapContainerStyles: { height: "500px" },
                ...projectOverviewMapConfig,
              },
            },
            icon: "Project_overview.png",
          },
          {
            id: "2-strategic-context-and-need",
            title: "Strategic context and need",
            content: {
              elementId: "2-strategic-context-and-need",
              hideContent: false,
            },
            icon: "Strategic_context_and_need.png",
          },
          {
            id: "3-airspace-constraints-and-project-settings",
            title: "Airspace constraints",
            content: {
              elementId: "3-airspace-constraints-and-project-settings",
              hideContent: false,
            },
            icon: "Airspace_constraints.png",
          },
          {
            id: "4-planning-and-assessment-process",
            title: "Planning and assessment process",
            content: {
              elementId: "4-planning-and-assessment-process",
              hideContent: false,
            },
            icon: "Planning_and_assessment_process.png",
          },
          {
            id: "5-project-development",
            title: "Project development",
            content: {
              elementId: "5-project-development",
              hideContent: false,
            },
            icon: "Project_development.png",
          },
          {
            id: "6-aircraft-noise",
            title: "Aircraft noise",
            content: {
              elementId: "6-aircraft-noise",
              hideContent: false,
            },
            icon: "Aircraft_noise.png",
          },
          {
            id: "7-air-quality-and-greenhouse-gas",
            title: "Air quality and greenhouse gas",
            content: {
              elementId: "7-air-quality-and-greenhouse-gas",
              hideContent: false,
            },
            icon: "Air_quality_and_greenhouse_gas.png",
          },
          {
            id: "8-aircraft-hazard-and-risk",
            title: "Aircraft hazard and risk",
            content: {
              elementId: "8-aircraft-hazard-and-risk",
              hideContent: false,
            },
            icon: "Aircraft_hazard_and_risk.png",
          },
          {
            id: "9-land-use",
            title: "Land use",
            content: {
              elementId: "9-land-use",
              hideContent: false,
            },
            icon: "Land_use.png",
          },
          {
            id: "10-landscape-and-visual-amenity",
            title: "Landscape and visual amenity",
            content: {
              elementId: "10-landscape-and-visual-amenity",
              hideContent: false,
              mapConfig: {
                mapContainerStyles: { height: "500px" },
                ...landscapeMapConfig,
              },
            },
            icon: "Landscape_and_visual_amenity.png",
          },
          {
            id: "11-biodiversity",
            title: "Biodiversity",
            content: {
              elementId: "11-biodiversity",
              hideContent: false,
              mapConfig: {
                mapContainerStyles: { height: "500px" },
                ...biodversityMapConfig,
              },
            },
            icon: "Biodiversity.png",
          },
          {
            id: "12-heritage",
            title: "Heritage",
            content: {
              elementId: "12-heritage",
              hideContent: false,
              mapConfig: {
                mapContainerStyles: { height: "500px" },
                ...heritageMapConfig,
              },
            },
            icon: "Heritage.png",
          },
          {
            id: "13-social",
            title: "Social",
            content: {
              elementId: "13-social",
              hideContent: false,
            },
            icon: "Social.png",
          },
          {
            id: "14-economic",
            title: "Economic",
            content: {
              elementId: "14-economic",
              hideContent: false,
            },
            icon: "Economic.png",
          },
          {
            id: "15-human-health",
            title: "Human health",
            content: {
              elementId: "15-human-health",
              hideContent: false,
            },
            icon: "Human_health.png",
          },
          {
            id: "16-facilitated-impacts",
            title: "Facilitated impacts",
            content: {
              elementId: "16-facilitated-impacts",
              hideContent: false,
            },
            icon: "Facilitated_impacts.png",
          },
          {
            id: "17-cumulative-impacts",
            title: "Cumulative impacts",
            content: {
              elementId: "17-cumulative-impacts",
              hideContent: false,
            },
            icon: "Cumulative_impacts.png",
          },
          {
            id: "18-matters-of-national-environmental-significance",
            title: "Matters of national environmental significance",
            content: {
              elementId: "18-matters-of-national-environmental-significance",
              hideContent: false,
              mapConfig: {
                mapContainerStyles: { height: "500px" },
                ...nationalMapConfig,
              },
            },
            icon: "Matters_of_national_environmental_significance.png",
          },
          {
            id: "19-mitigation-and-management",
            title: "Mitigation and management",
            content: {
              elementId: "19-mitigation-and-management",
              hideContent: false,
            },
            icon: "Mitigation_and_management.png",
          },
          {
            id: "20-conclusions",
            title: "Conclusions",
            content: {
              elementId: "20-conclusions",
              hideContent: false,
            },
            icon: "Conclusions.png",
          },
        ],
      },
    },
  },
];

const InteractiveEISPage = () => {
  return (
    <Container fluid className="page-view">
      <Page config={config} />
    </Container>
  );
};

export default InteractiveEISPage;
